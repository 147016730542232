import React, { useState, useContext, useEffect } from 'react';
import { Row, Col, Modal, Form, Button, FormCheck, Image, Container } from 'react-bootstrap'
import FontPicker from 'font-picker-react'
import { FiSettings } from 'react-icons/fi'
import SettingsContext from '../utility/SettingProvider'
import { MdColorLens, MdClose } from 'react-icons/md'
import { FaPlus, FaMinus, FaRegCopy } from 'react-icons/fa'
import Logo from './SSAB-Logo-no-text.png'

function SizeButton(props) {
    const style = {
        cursor: "pointer",
        fontSize: props.size,
        borderColor: "block",
        borderStyle: props.data.current === props.size ? "solid" : "hidden",
        borderWidth: "1px"
    }
    return <Col><span className={props.className} style={style} onClick={() => props.data.set(props.size)}>A</span></Col>
}

const colorArray = [
    ["black", "black", "white"],
    ["white", "white", "black"],
    ["black", "black", "yellow"],
    ["yellow", "yellow", "black"],
    ["blue", "blue", "yellow"],
    ["yellow", "yellow", "blue"],
    ["white", "black", "white"],
    ["yellow", "black", "white"]
]

function Settings(props) {
    const settings = useContext(SettingsContext)

    const [show, setShow] = useState(!settings.hideSettings)
    const [showButtons, setShowButtons] = useState(false)
    const [timer, setTimer] = useState()
    const [colorSelect, setColorSelect] = useState(0)
    const [link, setLink] = useState('')
    const [linkCopied, setLinkCopied] = useState(false)

    const hide = () => {
        setShow(false)
    }

    const toggleColors = () => {
        let i = colorSelect + 1
        if (i === colorArray.length)
            i = 0

        settings.backgroundColor.set(colorArray[i][0])
        settings.textBackground.set(colorArray[i][1])
        settings.color.set(colorArray[i][2])
        setColorSelect(i)
    }

    useEffect(() => {
        const onClick = () => {
            setShowButtons(true)
            clearInterval(timer)
            setTimer(setInterval(() => {
                setShowButtons(false)
            }, 5000))
        }
        window.addEventListener('click', onClick)
        window.addEventListener('dragstart', onClick)
        window.addEventListener('touchstart', onClick)

        return () => {
            window.removeEventListener('click', onClick)
            window.removeEventListener('dragstart', onClick)
            window.removeEventListener('touchstart', onClick)
            clearInterval(timer)
        }
    }, [timer])

    useEffect(() => {
        if (!show) {
            setLink('')
            setLinkCopied(false)
        }
    }, [show])

    return (
        <>
            {showButtons &&
                <>
                    <Button className="mr-1" variant="primary" onClick={e => {
                        e.preventDefault()
                        e.stopPropagation()
                        setShowButtons(false)
                    }}><MdClose /></Button>
                    <Button className="mr-1" variant="primary" onClick={() => {
                        const size = parseInt(settings.fontSize.current) + 10
                        settings.fontSize.set(size + 'px')
                    }}><FaPlus size={44} /></Button>
                    <Button className="mr-1" variant="primary" onClick={() => {
                        const size = Math.max(parseInt(settings.fontSize.current) - 10, 12)
                        settings.fontSize.set(size + 'px')
                    }}><FaMinus size={44} /></Button>
                    <Button className="mr-1" variant="primary" onClick={toggleColors}><MdColorLens size={44} /></Button>
                    <Button className="mr-1" variant="primary"><FiSettings size={44} onClick={() => setShow(true)} /></Button>
                </>}
            <Modal size='lg' show={show} onHide={hide} centered={true}>
                <Modal.Header closeButton>
                    <Container>
                        <Row>
                            <Col>
                                <Modal.Title>
                                    Ändra utseende
                                </Modal.Title>
                            </Col>
                            <Col xs="auto">
                                <Image src={Logo} width="22px" height="30px" />Skrivtolkning.se
                            </Col>
                        </Row>
                    </Container>
                </Modal.Header>
                <Modal.Body>
                    <Row className="mb-2">
                        <Col>
                            <h6>Font</h6>
                            <FontPicker apiKey={process.env.REACT_APP_FONT_API_KEY} activeFontFamily={settings.fontFamily.current} onChange={newFont => settings.fontFamily.set(newFont.family)} />
                        </Col>
                        <Col>
                            <Row>
                                <h6>Storlek</h6>
                            </Row>
                            <Row className="align-items-center">
                                <SizeButton size="22px" data={settings.fontSize} />
                                <SizeButton size="32px" data={settings.fontSize} />
                                <SizeButton size="42px" data={settings.fontSize} />
                                <SizeButton size="62px" data={settings.fontSize} />
                            </Row>
                        </Col>
                    </Row>
                    <Row className="justify-content-center">
                        <h6>Färger</h6>
                    </Row>
                    <Row className="justify-content-around">
                        {[
                            ["black", "white"],
                            ["white", "black"],
                            ["black", "yellow"],
                            ["yellow", "black"],
                            ["blue", "yellow"],
                            ["yellow", "blue"]
                        ].map((val, idx) => {
                            return (
                                <Button
                                    className="m-1"
                                    style={{ backgroundColor: val[0], borderColor: val[0], color: val[1] }}
                                    key={idx}
                                    onClick={() => {
                                        settings.backgroundColor.set(val[0])
                                        settings.color.set(val[1])
                                        settings.textBackground.set(val[0])
                                    }}>Text</Button>
                            )
                        })}
                        {[
                            ["white", "white", "black"],
                            ["yellow", "white", "black"]
                        ].map((val, idx) => {
                            return (
                                <Button
                                    size="lg"
                                    className="m-1"
                                    style={{ backgroundColor: val[0], borderColor: val[0], color: val[1] }}
                                    key={idx}
                                    onClick={() => {
                                        settings.backgroundColor.set(val[0])
                                        settings.color.set(val[1])
                                        settings.textBackground.set(val[2])
                                    }}><span style={{ backgroundColor: val[2] }}>Text</span></Button>
                            )
                        })}
                    </Row>
                    {
                        props.formatSettings &&
                        <>
                            <Row>
                                <Col>
                                    <Form.Label>Rader per block</Form.Label>
                                    <Form.Control value={settings.linesPerBlock.current} as="select" onChange={(e) => {
                                        settings.linesPerBlock.set(e.target.value)
                                    }}>
                                        <option value={1}>1</option>
                                        <option value={2}>2</option>
                                        <option value={3}>3</option>
                                    </Form.Control>
                                </Col>
                                <Col>
                                    <Form.Label>Tecken per rad</Form.Label>
                                    <Form.Control value={settings.charsPerLine.current} as="select" onChange={(e) => {
                                        settings.charsPerLine.set(e.target.value)
                                    }}>
                                        <option value={32}>32</option>
                                        <option value={40}>40</option>
                                        <option value={50}>50</option>
                                        <option value={60}>60</option>
                                    </Form.Control>
                                </Col>
                                <Col className="align-self-end">
                                    <Row>
                                        <FormCheck checked={settings.rollingText.current} onChange={(e) => settings.rollingText.set(e.target.checked)} type="checkbox" label="Rullande text" />
                                    </Row>
                                    <Row>
                                        <FormCheck checked={settings.asTextFeed.current} onChange={(e) => settings.asTextFeed.set(e.target.checked)} type="checkbox" label="Visa som textflöde" />
                                    </Row>
                                </Col>
                            </Row>
                        </>
                    }
                    <Row className="justify-content-center mt-2">
                        <Button onClick={hide}>Klar</Button>
                    </Row>
                    <Row className="justify-content-center">
                        <Button variant="link" onClick={() => {
                            setLink(window.location.href.split('?')[0] + settings.getSettingsString())
                        }}>Skapa länk med inställningar</Button>
                    </Row>
                    {!link ? null :
                        <>
                            <Row>
                                <Col>
                                    <Form.Control type="text" readOnly value={link} />
                                </Col>
                                <Col sm="auto">
                                    <Button onClick={() => {
                                        navigator.clipboard.writeText(link)
                                        setLinkCopied(true)
                                    }}><FaRegCopy /></Button>
                                </Col>
                            </Row>
                            <Row className="justify-content-center">
                                {linkCopied ? "Länk kopierad!" : null}
                            </Row>
                        </>
                    }
                </Modal.Body>
            </Modal>
        </>
    )
}

export default Settings