import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'
import './App.css';
import CaptionView from './views/CaptionView';
import { SettingsProvider } from './utility/SettingProvider'

function App() {
  const url = `${process.env.REACT_APP_HTTP_PROTOCOL}${process.env.REACT_APP_TELETYPIST_URL}/caption${window.location.pathname.replace("/caption", "")}`.replace("/listener", "")

  return (
    <SettingsProvider>
      <CaptionView url={url} />
    </SettingsProvider>
  )
}

export default App;
