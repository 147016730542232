import React, { useState, useEffect } from 'react';

const SettingContext = React.createContext()

export function SettingsProvider(props) {
    const [storedSettingsRaw] = useState(localStorage.getItem('skrivtolkningListenerSettings'))

    const storedSettings = {
        color: "white",
        fontSize: "32px",
        fontFamily: "Arial",
        backgroundColor: "black",
        textBackground: "black",
        topPadding: "0vh",
        textHeight: "100vh",
        charsPerLine: 40,
        linesPerBlock: 2,
        rollingText: false,
        asTextFeed: false,
        name: ""
    }

    if (storedSettingsRaw && storedSettingsRaw.length > 0) {
        const json = JSON.parse(storedSettingsRaw)
        if (json.rollingText === 'true')
            json.rollingText = true
        else
            json.rollingText = false
        if (json.asTextFeed === 'true')
            json.asTextFeed = true
        else
            json.asTextFeed = false
        Object.assign(storedSettings, json)
    }

    const params = new URLSearchParams(window.location.search)

    let hideSettingsInit = storedSettingsRaw && storedSettingsRaw.length > 0

    const setOptionFromQueryParam = option => {
        if (params.get(option)) {
            storedSettings[option] = params.get(option)
            hideSettingsInit = true
        }
    }

    setOptionFromQueryParam('color')
    setOptionFromQueryParam('fontSize')
    setOptionFromQueryParam('fontFamily')
    setOptionFromQueryParam('backgroundColor')
    setOptionFromQueryParam('textBackground')
    setOptionFromQueryParam('charsPerLine')
    setOptionFromQueryParam('linesPerBlock')
    setOptionFromQueryParam('name')

    if (params.get('rollingText') === 'true') {
        storedSettings.rollingText = true
        hideSettingsInit = true
    }
    if (params.get('asTextFeed') === 'true') {
        storedSettings.rollingText = true
        hideSettingsInit = true
    }

    const [color, setColor] = useState(storedSettings.color)
    const [fontSize, setFontSize] = useState(storedSettings.fontSize)
    const [fontFamily, setFontFamily] = useState(storedSettings.fontFamily)
    const [backgroundColor, setBackgroundColor] = useState(storedSettings.backgroundColor)
    const [textBackground, setTextBackground] = useState(storedSettings.textBackground)
    const [topPadding, setTopPadding] = useState(storedSettings.topPadding)
    const [textHeight, setTextHeight] = useState(storedSettings.textHeight)
    const [charsPerLine, setCharsPerLine] = useState(storedSettings.charsPerLine)
    const [linesPerBlock, setLinesPerBlock] = useState(storedSettings.linesPerBlock)
    const [rollingText, setRollingText] = useState(storedSettings.rollingText)
    const [hideSettings] = useState(hideSettingsInit)
    const [name, setName] = useState(storedSettings.name)
    const [asTextFeed, setAsTextFeed] = useState(storedSettings.asTextFeed)

    useEffect(() => {
        localStorage.setItem('skrivtolkningListenerSettings',
            JSON.stringify({
                color,
                fontSize,
                fontFamily,
                backgroundColor,
                textBackground,
                topPadding,
                textHeight,
                charsPerLine,
                linesPerBlock,
                rollingText,
                name,
                asTextFeed
            })
        )
    })

    const settings = {
        color: { current: color, set: setColor },
        fontSize: { current: fontSize, set: setFontSize },
        fontFamily: { current: fontFamily, set: setFontFamily },
        backgroundColor: { current: backgroundColor, set: setBackgroundColor },
        textBackground: { current: textBackground, set: setTextBackground },
        topPadding: { current: topPadding, set: setTopPadding },
        textHeight: { current: textHeight, set: setTextHeight },
        charsPerLine: { current: charsPerLine, set: setCharsPerLine },
        linesPerBlock: { current: linesPerBlock, set: setLinesPerBlock },
        rollingText: { current: rollingText, set: setRollingText },
        hideSettings: hideSettings,
        name: { current: name, set: setName },
        asTextFeed: { current: asTextFeed, set: setAsTextFeed },
        getSettingsString: () => `?name=${name}&color=${color}&fontSize=${fontSize}&fontFamily=${fontFamily}&backgroundColor=${backgroundColor}&textBackground=${textBackground}&charsPerLine=${charsPerLine}&linesPerBlock=${linesPerBlock}&rollingText=${rollingText}&asTextFeed=${asTextFeed}`
    }


    return (
        <SettingContext.Provider value={settings}>
            {props.children}
        </SettingContext.Provider>
    )
}

export default SettingContext