import React, { useState, useEffect, useContext } from 'react';
import xml from 'xml2js'
import Settings from './Settings';
import SettingContext from '../utility/SettingProvider';

const welcomeMessage = `Du är nu ansluten till live-textningen.<br>
Kom ihåg att du själv kan ändra storlek och färg på texten när du vill. Klicka vart som helst på skärmen för att få fram inställningsknapparna.<br>
Den här texten följer inte formateringsreglerna och visar därmed längre och fler rader än när textningen börjar.<br>
När en tolk ansluter och börjar skriva kommer texten visas här.`

function CaptionView(props) {
    const [lines, setLines] = useState([])
    const [timer, setTimer] = useState()
    const [showWelcomeMessage, setShowWelcomeMessage] = useState(true)

    const settings = useContext(SettingContext)

    useEffect(() => {
        if (timer)
            clearInterval(timer)

        if (settings.asTextFeed.current) {
            setTimer(setInterval(() => {
                fetch(props.url + `?charsPerLine=${settings.charsPerLine.current}&linesPerBlock=${settings.linesPerBlock.current}&rolling=${settings.rollingText.current ? 'yes' : 'no'}&asTextFeed=true`, {
                    method: "GET"
                })
                    .then(response => response.json())
                    .then(data => {
                        const newLines = data.Lines
                        for (const line of newLines) {
                            if (line !== "") {
                                setShowWelcomeMessage(false)
                            }
                        }
                        setLines(newLines)
                    })
                    .catch(err => console.log(err))
            }, 100))
        } else {
            setTimer(setInterval(() => {
                fetch(props.url + `?charsPerLine=${settings.charsPerLine.current}&linesPerBlock=${settings.linesPerBlock.current}&rolling=${settings.rollingText.current ? 'yes' : 'no'}`, {
                    method: "GET"
                })
                    .then(response => response.text())
                    .then(str => (new xml.Parser()).parseStringPromise(str))
                    .then(data => {
                        const newLines = Object.values(data.caption).map(line => line[0])
                        for (const line of newLines) {
                            if (line !== "") {
                                setShowWelcomeMessage(false)
                            }
                        }
                        setLines(newLines)
                    })
                    .catch(err => console.log(err))
            }, 100))
        }
    }, [props.url, settings.charsPerLine, settings.linesPerBlock, settings.rollingText, settings.asTextFeed])

    return (
        <>
            <div style={{
                backgroundColor: settings.backgroundColor.current,
                height: "100%",
                width: "100%",
                position: "absolute",
                paddingTop: settings.topPadding.current
            }}>
                <div style={{
                    fontFamily: settings.fontFamily.current,
                    color: settings.color.current,
                    fontSize: settings.fontSize.current,
                    backgroundColor: settings.backgroundColor.current,
                    padding: "20px",
                    width: "100%",
                    height: settings.textHeight.current,
                    overflow: "hidden",
                    position: "absolute"
                }}>
                    <div style={{ position: "absolute", minHeight: "98%", bottom: "1%", width: "98%" }}>
                        {showWelcomeMessage ? <span style={{ backgroundColor: settings.textBackground.current }} dangerouslySetInnerHTML={{ __html: welcomeMessage }} /> : lines.map((line, idx) => {
                            return <div key={idx}><span style={{ backgroundColor: settings.textBackground.current }} dangerouslySetInnerHTML={{ __html: line }} /></div>
                        })}
                    </div>
                </div>
            </div>
            <div style={{ position: "absolute", bottom: "1%", right: "1%" }}>
                <Settings formatSettings />
            </div>
        </>
    )
}

export default CaptionView